<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card class="min-vh-100">
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<ResponseAlert ref="response" />
					<b-col md="6">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('name')}" label="Name">
							<b-form-input v-model="models.name" v-validate="'required'" data-vv-name="name">
							</b-form-input>
							<div v-if="errors.has('name')">
								<p class="text-danger">{{ errors.first('name') }}</p>
							</div>
						</b-form-group>
            <b-form-group>
							<b-form-radio-group
								v-model="models.period"
								name="period"
								class="my-1"
							>
								<b-form-radio value="monthly">Monthly</b-form-radio>
								<b-form-radio class="mx-5" value="half-yearly">6 Months</b-form-radio>
								<b-form-radio value="yearly">Yearly</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
						<b-input-group prepend="Rp. " class="bg-white mb-4">
              <b-form-input type="number" no-wheel @keypress="checkValidationNumber" v-model="models.price"></b-form-input>
            </b-input-group>
						<b-form-group>
							<div class="col-sm-12 no-padding">
								<label class="radio-inline no-padding">
									<input type="radio" v-model="models.status" value="active">
									<span class="badge badge-success">Active</span>
								</label>
								<label class="radio-inline">
									<input type="radio" v-model="models.status" value="inactive">
									<span class="badge badge-danger">Inactive</span>
								</label>
							</div>
						</b-form-group>

						<div class="d-flex">
							<b-button type="submit" variant="primary" class="btn-min-width rounded">
								Save
							</b-button>
							<b-button type="button" variant="light" class="btn-min-width rounded" @click="$router.push({name: config.uri})">
								Cancel
							</b-button>
						</div>
					</b-col>
				</b-row>
			</b-card>
		</div>
	</form>
</template>
<script>
	import api from '@/utils/api.js'
	import {checkValidationNumber} from '@/utils/helpers.js'
	import ResponseAlert from '@/components/response_alert';

	export default {
		name: 'FormEpaper',
		components: {
			ResponseAlert,
		},
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					name: '',
          period: 'monthly',
          price: '',
					status: 'active'
				},
        checkValidationNumber
			}
		},
		created() {
			let _ = this
			_.getById();
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.$axios.get(`${api.package}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.name = data.name
							_.models.period = data.period
							_.models.price = data.price
							_.models.status = data.status
						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},
			
			save() {
				const _ = this;
				_.$validator.validate().then(valid => {
					if (valid) {
						let request = "";
						let message = "create"

						if (!_.$route.params.id) {
							request = _.$axios.post(api.package, _.models);
						} else {
							message = "update"
							request = _.$axios.put(`${api.package}/${_.$route.params.id}`, _.models);
						}
						request
							.then(() => {
								this.$showToast('Success', `Success ${message} package`)
								_.$router.push({
									name: _.config.uri
								})
							})
							.catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
					} else {
						window.scrollTo(0, 0);
					}
				});
			}
		}
	}
</script>